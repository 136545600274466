import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import NetlifyForm from "react-netlify-form";
import ReactMarkdown from "react-markdown";

import Content, { SidebarPanel } from "../components/Content";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Carousel from "../components/Carousel";
import PageWrapper from "../components/PageWrapper";
import Badge, { BadgeGroup } from "../components/Badge";

const StyledForm = styled.div`
  margin-top: 88px;
  textarea {
    resize: vertical;
  }

  textarea,
  input {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    border: 0;
    padding: 10px;
    font-size: 1em;
    font-family: Arial, sans-serif;
    color: #333;
    border: solid 1px #ccc;
    margin: 0 0 20px;
    width: 100%;
    transition: background-color 125ms ease-out;

    &[type="submit"] {
      background-color: ${({ theme }) => theme.red2};
      border: 2px solid ${({ theme }) => theme.red2};
      color: white;
      font-weight: 700;
      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.red3};
      }
    }
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: baseline;

  label {
    font-weight: 700;
    width: 30%;
    sup {
      color: red;
    }
  }
`;

const ContactUs = ({ data, location }) => {
  const { markdownRemark } = data;
  const { frontmatter, html, excerpt } = markdownRemark;
  const {
    title,
    badges,
    carousel,
    sidebar_content,
    upper_sidebar_content,
  } = frontmatter;
  const isCarousel = Boolean(carousel.length);
  const resolveFirst = i => (i === 0 && badges.length ? "first" : "");

  return (
    <Layout>
      <SEO title={title} description={excerpt} pathname={location.pathname} />
      <PageWrapper>
        <Content>
          <div className="main">
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <NetlifyForm name="contact-form">
              {({ loading, error, recaptchaError, success }) => (
                <>
                  {loading && <div>Loading...</div>}
                  {error && (
                    <div>
                      Your information was not sent. Please try again later.
                    </div>
                  )}
                  {recaptchaError && (
                    <div>
                      Recaptcha did not match. Please make sure the box is
                      checked.
                    </div>
                  )}
                  {success && (
                    <h2>
                      Thank you for contacting us! We will respond to your
                      inquiries as soon as possible.
                    </h2>
                  )}
                  {!loading && !success && (
                    <StyledForm>
                      <FormGroup>
                        <label htmlFor="full_name">
                          Name <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          name="full_name"
                          autoComplete="Full Name"
                          required
                          placeholder="Your full name"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="phone_number">
                          Phone Number <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          name="phone_number"
                          autoComplete="Phone Number"
                          required
                          placeholder="Your 10-digit phone number"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="email">
                          Email <sup>*</sup>
                        </label>
                        <input
                          type="email"
                          name="email"
                          autoComplete="Email Address"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="comment">
                          Comment <sup>*</sup>
                        </label>
                        <textarea
                          name="comment"
                          required
                          placeholder="Provide any information relevant to your request"
                        ></textarea>
                      </FormGroup>
                      <div data-netlify-recaptcha="true"></div>
                      <input type="submit" value="Send" />
                    </StyledForm>
                  )}
                </>
              )}
            </NetlifyForm>
          </div>
          <div className="sidebar">
            {upper_sidebar_content &&
              upper_sidebar_content.map(({ body }, i) => (
                <div
                  key={`${i}-upper-sidebar-content-${title}`}
                  className="upper-sidebar-container"
                >
                  <SidebarPanel className={i === 0 ? "first" : ""}>
                    <ReactMarkdown source={body} />
                  </SidebarPanel>
                </div>
              ))}
            {isCarousel ? (
              <div className="carousel-container">
                <Carousel hideBorder images={carousel} />
              </div>
            ) : null}
            <BadgeGroup>
              {badges.map((b, i) => (
                <Badge
                  key={`${i}-badge-content-${title}`}
                  to={b.link.url}
                  headline={b.headline}
                  buttonText={b.link.title}
                  icon={b.icon}
                />
              ))}
            </BadgeGroup>
            {sidebar_content.map(({ body }, i) => (
              <SidebarPanel
                key={`${i}-sidebar-content-${title}`}
                className={resolveFirst(i)}
              >
                <ReactMarkdown source={body} />
              </SidebarPanel>
            ))}
          </div>
        </Content>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "contact-us-content" } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        title
        slug
        parent
        carousel {
          image
          alt
          credit {
            name
            url
          }
        }
        sidebar_content {
          body
        }
        badges {
          icon
          link {
            title
            url
          }
          headline
        }
      }
    }
  }
`;

export default ContactUs;
